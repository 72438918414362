import React from "react"
import Link from "gatsby-link"
import slugify from "slugify"
import { ListGroup } from "react-bootstrap"
import * as styles from "./blog-post.module.css"

const OtherPosts = ({ posts }) => {
  const links = posts.map((post, i) => {
    return (
      <ListGroup.Item key={i} className={styles.listGroupItem}>
        <Link to={`/blog/${slugify(post.title, { lower: true })}`}>
          {post.title}
        </Link>
      </ListGroup.Item>
    )
  })

  return <ListGroup className={styles.otherPosts}>{links}</ListGroup>
}

export default OtherPosts

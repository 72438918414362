import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Contact from "../components/contact"
import OtherPosts from "./other-posts"
import { Row, Col } from "react-bootstrap"

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    relatedPosts: allFeedDevDoneBlog(
      limit: 3
      sort: { fields: isoDate, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
      }
    }
    post: feedDevDoneBlog(id: { eq: $id }) {
      title
      link
      creator
      content {
        encoded
      }
    }
  }
`

const BlogPost = ({ data }) => {
  const post = data.post
  return (
    <>
      <Row className="mt-5">
        <Col>
          <Helmet>
            <title>
              {post.title} - {data.site.siteMetadata.title} Blog
            </title>
            <link rel="canonical" href={post.link} />
          </Helmet>
          <header className="major">
            <h2>{post.title}</h2>
          </header>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={{ span: 10, offset: 1 }} className="blog-post">
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: post.content.encoded }}
          ></div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <h3>If you enjoyed that, try these:</h3>
          <OtherPosts posts={data.relatedPosts.nodes} />
        </Col>
      </Row>
      <Contact />
    </>
  )
}
export default BlogPost;